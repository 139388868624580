import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';
import { getTransformedPersonVouchersData } from '@/store/helpers/vouchers';

export const vouchersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getVouchersByPersonId: build.query({
      async queryFn(
        { personId, token }:
        { personId: string, token?: string },
        { extra },
      ) {
        const { VouchersService } = extra as Services;
        try {
          const results = await VouchersService.setToken(token)
            .setServerSideBaseUrl()
            .getVouchersByPersonid(personId);
          const transformedVouchersList = getTransformedPersonVouchersData(results?.data);
          return { data: transformedVouchersList };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Vouchers'],
    }),
    postVoucher: build.mutation({
      async queryFn(
        { voucher, token }:
        { voucher: JSON, token?: string },
        { extra },
      ) {
        const { VouchersService } = extra as Services;
        try {
          const results = await VouchersService.setToken(token)
            .setServerSideBaseUrl()
            .postVouchers(voucher);

          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      invalidatesTags: ['Vouchers'],
    }),
  }),
});

export const {
  useGetVouchersByPersonIdQuery,
  usePostVoucherMutation,
} = vouchersApi;
